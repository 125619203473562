export const translations = {
    "ru": {
        "text1": { "label": "Станьте нашим", "color": "white" },
        "text2": { "label": "партнером", "color": "#BDFF00" },
        "text3": { "label": "Оставьте заявку, наши специалисты с вами свяжутся", "color": "white" },
        "text4": { "label": "Оставить заявку", "color": "black" },
        "text5": { "label": "Скачать sorpa pay", "color": "white" },
        "text6": { "label": "Наша миссия", "color": "black" },
        "text7": { "label": "Мы стремимся объединить предпринимателей и\nклиентов в экосистеме взаимной выгоды, где\nкаждая транзакция приносит радость и пользу", "color": "black" },
        "text8": { "label": "Sorpa - всегда с добавкой!", "color": "black" },
        "text9": { "label": "Правила платежной организаций", "color": "black" },
        "text10": { "label": "Правила пользования", "color": "black" },
        "text11": { "label": "Политика конфиденциальности", "color": "black" },
        "text12": { "label": "(c) Разработано Частная Компания \"Clover Ltd\"." +
                " Все права защищены ТОО “OMASA group” 2024.\n", "color": "black" },
    },
    "kk": {
        "text1": { "label": "Біздің", "color": "white" },
        "text2": { "label": "Серіктестеріміз", "color": "#BDFF00" },
        "text22": { "label": "\n болыңыз", "color": "white" },
        "text3": { "label": "Өтініш қалдырыңыз, біздің мамандарымыз сізбен хабарласады", "color": "white" },
        "text4": { "label": "Өтініш қалдыру", "color": "black" },
        "text5": { "label": "sorpa pay жүктеп алу", "color": "white" },
        "text6": { "label": "Біздің миссиямыз", "color": "black" },
        "text7": { "label": "Біз әрбір транзакция қуаныш пен пайда әкелетін\nөзара тиімді экожүйеде кәсіпкерлер мен клиенттерді\nбіріктіруге ұмтыламыз", "color": "black" },
        "text8": { "label": "Sorpa - Әрқашан қосымшамен!", "color": "black" },
        "text9": { "label": "Төлем ұйымының ережелері", "color": "black" },
        "text10": { "label": "Пайдалану ережелері", "color": "black" },
        "text11": { "label": "Құпиялылық саясаты", "color": "black" },
        "text12": { "label": "(c) Частная Компания Clover Ltd. әзірленген. “OMASA group” ЖШС 2024. Барлық құқықтар қорғалған.", "color": "black" },
    }
};
